// @ts-nocheck
/* eslint-enable */
/* eslint-disable react-hooks/exhaustive-deps */
// App
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';

// Actions
import {
  FilterActions_handleFilterChange,
  FilterActions_handleReduxOnlyFilterChange,
} from 'app/shared/flux/actions/FilterActions';
import AppActions from 'app/shared/flux/actions/AppActions';

// Lodash
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';

// Constants
import VALUE_CONSTANTS from 'app/shared/constants/ValueConstants';

// Tracking
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

// Misc / utils
import { adapt_reduxToQuery } from 'app/shared/flux/actions/FilterActions/adapters';

export const useKeyboardNavigation = ({ focusRef, refList, showDropdown, toggleDropdown }) => {
  let currentIdx = -1;

  const _outOfBoundsGuard = (idx) => {
    const minIndex = -1;
    const maxIndex = refList.length;

    if (idx >= minIndex && idx < maxIndex) {
      return true;
    }

    return false;
  };

  const _moveUpList = () => {
    currentIdx--;
    toggleDropdown(true);
    const shouldDecrement = _outOfBoundsGuard(currentIdx);
    if (shouldDecrement && currentIdx >= 0) {
      refList[currentIdx].current.focus();
    }

    if (currentIdx < 0) {
      focusRef.current.focus();
      toggleDropdown(false);
    }
  };

  const _moveDownList = () => {
    const shouldIncrement = _outOfBoundsGuard(currentIdx + 1);

    if (shouldIncrement) {
      currentIdx++;
      toggleDropdown(true);
      refList[currentIdx].current.focus();
    }
  };

  const basicKeyDownNav = (keyCode) => {
    switch (keyCode) {
      case VALUE_CONSTANTS.DOWN_ARROW_KEYCODE:
        if (!showDropdown) {
          toggleDropdown(true);
        } else {
          _moveDownList();
        }
        break;
      case VALUE_CONSTANTS.ESC_KEYCODE:
        toggleDropdown(false);
    }

    return;
  };

  const dropdownKeyDownNav = (keyCode) => {
    switch (keyCode) {
      case VALUE_CONSTANTS.ESC_KEYCODE:
        toggleDropdown(false);
        focusRef.current.focus();
        break;
      case VALUE_CONSTANTS.UP_ARROW_KEYCODE:
        _moveUpList();
        break;
      case VALUE_CONSTANTS.DOWN_ARROW_KEYCODE:
        _moveDownList();
        break;
    }
    return;
  };

  const resetCurrentIdx = () => {
    currentIdx = -1;
  };

  return [basicKeyDownNav, dropdownKeyDownNav, resetCurrentIdx];
};

export const useFilterChange = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const urlQuery = useSelector((state) => state.location.current.query);
  const [isFilterResetting, setIsFilterResetting] = useState(false);
  let abortController = new AbortController();

  const debouncedFetch = debounce((args, abortControllerSignal) => {
    dispatch(FilterActions_handleFilterChange(args, abortControllerSignal));
  }, 1000);

  const debouncedFilterChange = useCallback((args) => {
    // If AbortController already exists, then abort! (i.e. user has clicked a filter again)
    if (abortController) {
      abortController.abort();
    }

    // Instantiate a new AbortController
    abortController = new AbortController();
    debouncedFetch(args, abortController.signal);
  }, []);

  const filterChange = (args) => dispatch(FilterActions_handleFilterChange(args));

  const reduxOnlyChange = (args) => dispatch(FilterActions_handleReduxOnlyFilterChange(args));

  const resetFilter = (args) => {
    const preventDefault = args?.preventDefault ?? false;
    setIsFilterResetting(true);
    dispatch(
      preventDefault ? FilterActions_handleReduxOnlyFilterChange(args) : FilterActions_handleFilterChange(args),
    ).then(() => {
      if (!args) {
        dispatch(
          AppActions.toggleNotificationMessage({
            content: 'Your filters have been reset.',
          }),
        );
      }
      setIsFilterResetting(false);
    });
  };

  const shouldFetchFilteredResults = () => {
    const adaptedFilter = adapt_reduxToQuery({ filter });
    const sanitizedAdaptedFilter = omitBy(adaptedFilter, (v) => v === '' || isNil(v));
    const adaptedUrlQuery = omit(urlQuery, ['lat', 'lon', 'z']);

    if (isEqual(adaptedUrlQuery, sanitizedAdaptedFilter)) {
      return false;
    } else {
      return true;
    }
  };

  return {
    debouncedFilterChange,
    filterChange,
    reduxOnlyChange,
    resetFilter,
    shouldFetchFilteredResults,
    isFilterResetting,
  };
};

export const useFilterTracking = () => {
  const dispatch = useDispatch();

  const trackFilterEvent = useYieldCallback(
    ({
      customTrackingEvent,
      isInlineFilterEvent = false,
      filterName,
      trackingLabel = '',
      newLaneEvent = null,
    } = {}) => {
      if (customTrackingEvent) {
        dispatch(analyticsEvent(customTrackingEvent));
      } else {
        dispatch(
          analyticsEvent(
            isInlineFilterEvent ? gaEvents.INLINE_FILTER_HANDLE_CHANGE : gaEvents.ADVANCED_FILTER_HANDLE_CHANGE,
            {
              action: filterName,
              label: trackingLabel,
              newLaneEvent,
            },
          ),
        );
      }
    },
  );

  return [trackFilterEvent];
};
