import type { ClickstreamEvent } from 'app/types';
import { Envelope } from './Envelope';

// TODO: Combine redudant code
type TriggerLocations = 'hp_homepage' | 'post_contact_popup' | 'search_results';
type TriggerObjects =
  | 'info_cards'
  | 'hp_home_page_component|search'
  | 'filter_panel'
  | 'post_contact_popup|alerts_modal';
type BedRoomFilterObjects = 'filter_panel' | 'info_cards';

export const TrackBedroomFilter = ({
  triggerLocation,
  triggerObject,
}: {
  triggerLocation: TriggerLocations;
  triggerObject: BedRoomFilterObjects;
}): ClickstreamEvent => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5816',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'bedroom_filter',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'bedroom_filter',
    },
  };

  return csPayload;
};

export const TrackBathroomFilter = ({ triggerLocation }: { triggerLocation: TriggerLocations }): ClickstreamEvent => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5817',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'bathroom_filter',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'bathroom_filter',
    },
  };

  return csPayload;
};

export const TrackPetsFilter = ({ triggerLocation }: { triggerLocation: TriggerLocations }): ClickstreamEvent => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5818',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'pets_filter',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'pets_filter',
    },
  };

  return csPayload;
};

export const TrackLowPriceFilter = ({
  triggerLocation,
  triggerObject,
}: {
  triggerLocation: TriggerLocations;
  triggerObject: TriggerObjects;
}): ClickstreamEvent => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5819',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'low_price_slider',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'low_price_filter',
    },
  };

  return csPayload;
};

export const TrackHighPriceFilter = ({
  triggerLocation,
  triggerObject,
}: {
  triggerLocation: TriggerLocations;
  triggerObject: TriggerObjects;
}): ClickstreamEvent => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5820',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'high_price_slider',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'high_price_filter',
    },
  };

  return csPayload;
};

export const TrackLaundryFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5884',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'laundry_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'laundry_filter',
    },
  };
  return csPayload;
};

export const TrackAcFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5885',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'ac_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'ac_filter',
    },
  };
  return csPayload;
};

export const TrackHeatingFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5886',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'heating_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'heating_filter',
    },
  };
  return csPayload;
};

export const TrackParkingFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5887',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'parking_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'parking_filter',
    },
  };
  return csPayload;
};

export const TrackGatedFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5888',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'gated_entry_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'gated_entry_filter',
    },
  };
  return csPayload;
};

export const TrackDoormanFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5889',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'doorman_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'doorman_filter',
    },
  };
  return csPayload;
};

export const TrackGymFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5891',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'gym_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'gym_filter_interaction',
    },
  };
  return csPayload;
};

export const TrackPoolFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5893',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'pool_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'pool_filter',
    },
  };
  return csPayload;
};

export const TrackDishwasherFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5894',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'dishwasher_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'dishwasher_filter',
    },
  };
  return csPayload;
};

export const TrackFurnishedEvent = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5895',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'furnished_filter_checkbox',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'furnished_filter',
    },
  };
  return csPayload;
};

export const TrackResetFilter = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5992',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'hp_homepage',
      trigger_source_nm: 'link_to_reset_filters',
      trigger_object_nm: 'hp_home_page_component|search',
    },
    semantic: {
      semantic_event_nm: 'reset_filter',
    },
  };
  return csPayload;
};
