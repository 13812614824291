// @ts-nocheck
/* eslint-enable */
import styled, { keyframes } from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';
import { inset, stacked } from 'app/shared/styles/_spacing';

import ReactSlider from 'react-slider';

const _slideUp = keyframes`
    0% {
        opacity: 0;
        transform: scaleY(0.2);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
`;

export const HistogramContainer = styled.section`
  ${inset._xs};
`;

export const NoListingsNotice = styled.p`
  ${font.sm};
  position: relative;
  color: ${colors['$hpx-grey-600']};
  font-weight: bold;
  text-align: center;
  top: 100px;
`;

export const HistogramSlider = styled(ReactSlider)`
  ${stacked._3x};
  margin-top: 48px;
  height: 100px;
`;

export const SliderTrack = styled.div`
  bottom: -6px;
  height: 6px;
  background: ${(props) => (props.index === 1 ? `${colors['$hpx-teal-500']}` : `${colors['$hpx-grey-500']}`)};
  border-radius: 999px;
`;

export const SliderThumb = styled.div`
  bottom: -16px;
  cursor: grab;
  line-height: 30px;
  width: 30px;
  border: 1px solid ${colors['$hpx-grey-500']};
  border-radius: 4px;
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 15%);
  background: ${colors['$hpx-white']};
  color: ${colors['$hpx-grey-500']};
  font-size: 24px;
  letter-spacing: -1px;
  text-align: center;
  z-index: 3;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const HistogramMedianLine = styled.div`
  position: absolute;
  top: -8px;
  height: 108px;
  border-left: 1px dashed ${colors['$hpx-grey-600']};
  z-index: ${zindex['$z-index-base']};

  ${(props) =>
    props.$position &&
    `
        left: ${props.$position}px;
    `}
`;

export const HistogramMedianText = styled.p`
  position: absolute;
  top: -24px;
  ${font.tiny};
  color: ${colors['$hpx-grey-600']};

  ${(props) =>
    props.$position &&
    `
        left: ${props.$position - 42}px !important;
    `}
`;

export const HistogramBars = styled.div`
  background-color: ${colors['$hpx-teal-500']};
  width: 2px;
  transform-origin: bottom;
  transition: height 0.4s ease;
  animation: ${_slideUp} 0.4s ease-out;
  bottom: 0;

  ${(props) =>
    props.$height &&
    `
        height: ${props.$height}%;
    `}

  ${(props) =>
    props.$grayBarColor &&
    `
        background-color: ${colors['$hpx-grey-100']}
    `}
`;

export const InputContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const NumberInputContainer = styled.section`
  display: inline-block;
  position: relative;
  border: 1px solid ${colors['$hpx-grey-500']};
  border-radius: 4px;
  width: 134px;
  text-align: center;
  ${font.sm};
`;

export const RangeText = styled.p`
  ${font.sm};
  color: ${colors['$hpx-grey-600']};
  padding-left: 15px;
  padding-right: 15px;
`;

export const NumberInput = styled.input`
  border: none;
  text-align: center;

  ${font.sm};
  margin: 0;
  letter-spacing: 0.5px;
  padding: 8px;
  margin-left: 2px;
  color: ${colors['$hpx-blue-600']};
  height: 34px;
  width: 100px;
`;

export const Skeleton = styled.section`
  height: 216px;
`;
