// @ts-nocheck
/* eslint-enable */
import React from 'react';
import loadable from '@loadable/component';

const BedBathComboFilter = loadable(
  () =>
    import(
      /* webpackChunkName: "BedBathComboFilter.loadable" */ 'app/shared/modules/MobileSRP/Header/FilterModules/BedBathComboFilter/component'
    ),
);

interface Props {
  showResetButton: boolean;
  hideBath: boolean;
  preventDefault: boolean;
  customTrackingEvent: string;
  triggerLocation: boolean;
  triggerObject: boolean;
}

const LoadableBedBathComboFilter = (props: Props) => {
  return <BedBathComboFilter {...props} />;
};

export default LoadableBedBathComboFilter;
