// @ts-nocheck
/* eslint-enable */
// App
import React, { useEffect, useState } from 'react';

// Misc / utils
import VALUE_CONSTANTS from 'app/shared/constants/ValueConstants';
import * as S from './styles';

const PriceInput = ({
  ariaLabel,
  disabled,
  handleSliderValueChange,
  id,
  isMaxInput,
  isMinInput,
  name,
  placeholder,
  value,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [localInputValue, setLocalInputValue] = useState(value);
  const ONE_TO_HUNDRED_SCALE = 1 / VALUE_CONSTANTS.PRICE_FILTER_INCREMENT;
  const INPUT_MIN = 0;
  const INPUT_MAX = 5000;

  // Updates to the slider should also update the input
  useEffect(() => {
    setLocalInputValue(value);
  }, [value]);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    const enterKeyPressed = keyCode === VALUE_CONSTANTS.ENTER_KEYCODE;

    if (enterKeyPressed && localInputValue !== value) {
      handleSliderValueChange(Math.floor(localInputValue * ONE_TO_HUNDRED_SCALE));
    }
  };

  const handleInputBlur = () => {
    if (localInputValue !== value) {
      // Only trigger a slider value change if the values did actually change!
      handleSliderValueChange(Math.floor(localInputValue * ONE_TO_HUNDRED_SCALE));
    }
    setIsFocused(false);
  };

  const handleLocalInputChange = (e) => {
    const val = Number(e.target.value);
    setLocalInputValue(val);
  };

  return (
    <S.NumberInputContainer>
      {isMinInput && (isFocused || value > INPUT_MIN) && <span aria-hidden="true">$</span>}
      {isMaxInput && (isFocused || value < INPUT_MAX) && <span aria-hidden="true">$</span>}
      <S.NumberInput
        aria-label={ariaLabel}
        disabled={disabled}
        id={id}
        max={INPUT_MAX}
        min={INPUT_MIN}
        name={name}
        onChange={handleLocalInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        placeholder={isFocused ? '' : placeholder}
        step="100"
        type="number"
        value={localInputValue === INPUT_MIN || localInputValue === INPUT_MAX ? '' : localInputValue}
      />
    </S.NumberInputContainer>
  );
};

export default PriceInput;
